.v-divider {
    margin-top: 1rem;
    margin-bottom: 3rem;
}

/**************** Menu styles ****************/
.section-header {
    font-weight: bold;
    font-size: x-large;
    margin-bottom: 1rem;
}

.dish-container {
    padding-top: 1rem;
}

.dish-container:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.2);
}

.dish-img {
    width: 75px;
    height: 75px;
    margin: auto;
}

.dish-name {
    font-size: large;
    margin-bottom: 0;
}

.dish-desc {
    font-weight: 200;
    min-width: 200px;
}


.dish-price {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 0;
}

.vege {
    color: rgb(43, 154, 33);
}

/************** Menu styles (SM) **************/
.dish-price-wrapper {
    width: 99px; /* match img */
}

/**************** Modal styles ****************/
.modal-body {
    text-align: center !important;
    min-height: 200px;
}

.modal-img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
}

/**************** Filters styles ****************/
.filter-container {
    margin: 0;
    padding: 10px;
}

.lang-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
