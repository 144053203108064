.checkout-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    gap: 3rem 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.checkout-wrapper h4 {
    margin-bottom: 1rem;
}

.checkout-wrapper button {
    width: 100%;
}

.checkout-go-back-button {
    margin-top: 0.5rem;
}

/**** Cart summary ****/
.cart-summary-outer {
    flex: 1;
    min-width: 300px;
    max-width: 500px;
    margin: auto;
}

.cart-summary {
    font-family: monospace;
    font-size: small;
    text-align: center;

    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 20px;
}

.cart-summary p {
    margin: 0;
}

.cart-summary-hr {
    border: none;
    height: 2px;
    background: repeating-linear-gradient(90deg,#000,#000 8px,transparent 8px,transparent 16px);
}

.cart-summary-item {
    font-size: larger;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.cart-summary-item-name {
    text-align: left;
}

.cart-summary-item-price {
    text-align: right;
}

.cart-summary-row {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: flex-start;
}

.cart-summary-sum {
    font-size: x-large;
    font-weight: bold;
}

.cart-summary-pay {
    font-size: large;
    font-weight: bold;
}

/**** Checkout form ****/
.form-wrapper {
    flex: 1;
    min-width: 325px;
    max-width: 600px;
}

.form-input-group {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
}

.form-label {
    font-size: smaller !important;
    min-width: 126px;
}

.form-input-invoice .form-label {
    min-width: unset;
}

.form-label span {
    color: rgb(187, 37, 37);
}

.form-input-invoice {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.form-input-subtitles {
    font-size: smaller;
}

.form-input-invoice-check {
    min-width: 150px;
}

.form-small-desc {
    font-size: smaller;
}

.form-explainer {
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
