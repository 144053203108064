.orders-loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
}

/********* Order Section *********/
.section-wrapper {
    margin-bottom: 3rem;
}
.section-wrapper h4 {
    margin-bottom: 1rem;
}

/*********** Order Item **********/
.order-item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.order-item-wrapper:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.2);
}

.order-item-img {
    width: 75px;
    height: 75px;
}

.order-item-desc {
    font-size: smaller;
}

.order-item-button {
    position: relative;
    width: 150px;
    height: 50px;
    
    background-color: #91c4de !important;
    border: white solid 1.5px !important;
}

.order-item-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-item-button-content svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.order-item-button-count {
    position: absolute;
    top: -10px;
    right: -10px;

    background-color: white;
    width: fit-content;
    height: fit-content;
    min-width: 24px;
    min-height: 24px;
    border-radius: 12px;

    color: #91c4de;
    font-size: medium;
}

@media only screen and (max-width: 700px) {
    .order-item-desc {
        display: none;
    }
}

@media only screen and (max-width: 500px) {
    .order-item-desc {
        display: none;
    }
    .order-item-button {
        width: 80px;
    }
    .order-item-button span {
        font-size: smaller;
    }
    .order-item-button svg {
        display: none;
    }
}
