.image {
    width: 100%;
    height: 100%;
}

.image-wrap {
    max-width: 600px;
    min-width: 300px;
    margin: auto;
}
