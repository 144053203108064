.details-card {
    background-color: transparent !important;
    border: none !important;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.details-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.details-image img {
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 500px;
    max-height: 500px;
}

.title {
    font-size: x-large !important;
}

.subtitle {
    color: grey;
    font-size: smaller !important;
}
