.wrapper {
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    background-color: #d8d8d6;
    border-radius: 20px;
    text-align: center;
}

@media only screen and (max-width:760px)
{
    .wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
