body {
  text-align: center;
  background-color: #d8d8d6 !important;
  background-image: url("./assets/background.jpg") !important;
}

.anim-page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.anim-page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.anim-page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.anim-page-exit {
  opacity: 1;
  transform: scale(1);
}

.anim-page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
