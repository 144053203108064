.header {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.image-wrap {
    max-width: 600px;
    min-width: 300px;
}

.image {
    width: 100%;
    height: 100%;
}
