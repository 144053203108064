.service-tel {
    white-space: nowrap;
}

.services-container p {
    max-width: 700px;
    margin: auto;
}

.service-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-image {
    margin: auto;
    width: 100%;
    height: 100%;
    max-height: 400px;
}

.service-image:hover {
    transform: scale(1.03);
    transition-duration: 0.3s;
}

.services-container table {
    width: 100%;
    max-width: 500px;
    margin: auto;
    text-align: left;
    font-size: smaller;
}

.services-container td,tr {
    border: 1px solid black;
    padding: 5px;
}

.services-container td:nth-child(2) {
    text-align: center;
}

.services-container tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.5);
}

/*** Service Filters ***/
.service-filters {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    gap: 1rem;

    cursor: pointer;

    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.service-filter-active {
    -moz-box-shadow: 0px 0px 3px 5px #91c4de;
    -webkit-box-shadow: 0px 0px 3px 5px #91c4de;
    box-shadow: 0px 0px 3px 5px #91c4de;

    -moz-transition: all 200ms linear;
    -webkit-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
}

.service-filters-item {
    flex: 1 1 auto;
    max-width: 250px;
    width: 100%;
    height: 100%;
    background-color: #d8d8d6 !important;
}

.service-filters-item img {
    object-fit: cover;
    aspect-ratio: 2 / 3;
}

.service-filters-item button {
    line-height: 1.2em;
    min-height: 4em;
    word-wrap: normal;
}
@media only screen and (max-width: 720px) {
    .service-filters-item button {
        font-size: smaller;
    }
}
@media only screen and (max-width: 430px) {
    .service-filters-item button {
        font-size: x-small;
        min-height: 5em;
        padding: 0;
    }
}
