.text {
    margin-top: 2rem;
    background-color: #d8d8d6;
}

.img-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.img-container img {
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 700px;
}

.hashtags {
    color: rgb(96, 96, 255);
    cursor: default;
}

.hashtags p {
    max-width: 500px;
    margin: auto;
}

.snowfall {
    position: fixed;
    width: 100vw;
    height: 100vh;
}
