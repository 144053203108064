.wrapper {
    max-width: 950px !important;
    width: 90% !important;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
@media only screen and (max-width: 720px) {
    .wrapper {
        width: 100% !important;
        margin-top: 0.5rem !important;
        margin-bottom: 1rem !important;
    }
}
