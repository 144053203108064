/* Cart button */
.cart-button {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 100;

    width: 150px;
    height: 56px;

    background-color: #91c4de;
    border: white solid 3px;
    border-radius: 25px;

    font-size: large;
    color: white;
}

.cart-button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.cart-button svg {
    width: 20px;
    height: 20px;
}

.cart-button-count {
    background-color: white;
    width: fit-content;
    height: fit-content;
    min-width: 24px;
    min-height: 24px;
    border-radius: 12px;

    color: #91c4de;
    font-size: medium;
}

.cart-modal-empty {
    margin-top: 1rem;
    text-align: center;
    font-size: large;
}

/* Cart modal */
.cart-modal-item {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem !important;
}

.cart-modal-item-sm-row {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.cart-modal-footer {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    margin-bottom: 1rem;
}

.cart-modal-footer button {
    height: 100%;
    width: 100%;
}

.cart-modal-total {
    font-size: smaller;
}

.cart-modal-total-min {
    margin-top: -0.9rem !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    font-size: smaller;
    text-align: center;
}

/* Cart item count */
.cart-item-count-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
}

.cart-item-count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.cart-item-count input {
    width: 50px !important;
    text-align: center;
}

.cart-item-count-del {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    width: 30px;
    height: 30px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0;
}

/* Cart animation */
.cart-anim-enter-active {
    transform: scale(0.9);
    transition: transform 200ms;
}

.cart-anim-enter-done {
    transform: scale(1);
    transition: transform 200ms;
}
.cart-anim-exit-active {
    transform: scale(1.1);
    transition: transform 200ms;
}

.cart-anim-exit-done {
    transform: scale(1);
    transition: transform 200ms;
}
