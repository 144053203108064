.catering-image {
    margin: auto;
    width: 100%;
    height: 100%;
    max-height: 300px;
    border-radius: 5%;
}

.catering-image:hover {
    transform: scale(1.03);
    transition-duration: 0.3s;
}