.wrapper {
    margin-top: 2rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: unset !important;
}

.photo-wrapper {
    display: flex;
    width: 300px;
    height: 300px;
    align-items: center;
    justify-content: center;
    border: 5px aliceblue solid;
    background-color: #d8d8d6;
}

span.lazy-load-image-background {
    display: flex !important;
    width: 100%;
    height: 100%;
}

.photo-image {
    margin: auto;
    width: auto;
    height: auto;
    max-width: 290px;
    max-height: 290px;
}

.photo-image:hover {
    transform: scale(1.03);
    transition-duration: 0.3s;
}

@media only screen and (max-width:900px)
{
    .wrapper {
        gap: 0.9rem;
    }

    .photo-wrapper {
        width: 200px;
        height: 200px;
    }

    .photo-image {
        max-width: 190px;
        max-height: 190px;
    }
}

@media only screen and (max-width:500px)
{
    .wrapper {
        gap: 0.7rem;
    }

    .photo-wrapper {
        width: 150px;
        height: 150px;
    }

    .photo-image {
        max-width: 140px;
        max-height: 140px;
    }
}

@media only screen and (max-width:380px)
{
    .wrapper {
        gap: 0.5rem;
    }

    .photo-wrapper {
        width: 110px;
        height: 110px;
    }

    .photo-image {
        max-width: 100px;
        max-height: 100px;
    }
}

