.rules-content {
    text-align: justify;
    padding: 10px;
    padding-right: 20px;
}

.rules-content h2 {
    text-align: center;
}

.rules-content p {
    margin-bottom: 2rem;
}

.rules-content li {
    margin-bottom: 0.5rem;
}
