.subtitle {
    margin-top: 1rem;
    opacity: 0.75;
}

.subsubtitle {
    font-size: medium;
    opacity: 0.5;
}

.logo {
    height: 10rem;
}

.content-image {
    height: 300px;
}

.funding-image {
    height: 150px;
}

.phone-header {
    font-variant-caps: small-caps;
    text-align: center;
}

.phone-number {
    font-size: larger;
    text-align: center;
}

.content-col {
    margin-bottom: 1rem;
}

.google-maps-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
}

.google-maps-icon div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: white;
}

.google-maps-icon img {
    width: 22px;
    height: auto;
}

.map-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 300px;
    height: 300px;
    margin: 0 !important;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    font-size: small;
}

.footer a, .footer span {
    text-decoration: none;
    color: black;
    opacity: 0.5;
}
