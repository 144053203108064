.snowfall {
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.vid-container {
    margin: auto;
    width: 100%;
    height: 100%;
    max-height: 400px;
}

.img-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.img-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.img-container img {
    margin: auto;
    width: 100%;
    height: 100%;
    max-height: 350px;
}

.img-container img:hover {
    transform: scale(1.03);
    transition-duration: 0.3s;
}
